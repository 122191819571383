.products-page {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(221, 221, 221, 1) 95%
  );
}

.products-page-container {
  max-width: 1410px;
  margin: auto;
  margin-top: 0px;
  padding: 100px 0 100px 0;
}

.products-page-container h1 {
  text-align: center;
  font-weight: 900;
  font-size: 64px;
  /* color: white; */
  /* border-bottom: 3px white solid; */
  border-bottom: 3px #000000 solid;
  color: #000000;
  padding: 10px;
  margin-bottom: 20px;
}

.header-products {
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
}

.products-page {
  height: 100%;
  display: block;
  margin: auto;
  max-width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-products-product-page {
  margin: 0px;
  padding: 10px !important;
  position: relative;
}

.header-products-product-page img:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.header-products-product-page img {
  max-width: 100%;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* filter: grayscale(70%); */
  filter: brightness(70%);

  width: 450px;
  height: 270px;
}

.header-products-product-page h2 {
  font-size: 32px;
  font-weight: 1000;
}

.header-products {
  position: relative;
}

.header-products-texts-page {
  position: absolute;
  color: rgb(255, 255, 255);
  left: 15px;
  top: 15px;
  z-index: 100;
}

.header-products-texts-page h3 {
  font-weight: 1000;
  font-size: 3vmin;
}

@media only screen and (max-width: 600px) {
  .products-page-container h1 {
    font-size: 8vmin;
    margin: 0px 20px 0px 20px;
  }
}