@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

html {
  overflow: hidden;
  height: 100%;
  scroll-behavior: smooth;
  /* -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scroll-snap-type: y mandatory;
}

body {
  overflow: auto;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  height: 100%;
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif ;
}

body * {
  box-sizing: border-box;
}

.whatsapp {
  position: absolute;
  right: 30px;
  bottom: 20px;
}

.whatsapp img {
  width: 64px;
  height: 64px;
}

.one-product-title-container h1 {
  font-size: 5vmin !important;
}

.header-products-texts-page h2 {
  /* font-size: 2vmin !important; */
  font-size: 2.3rem !important;
  padding: 10px !important;
}