
.footer-companies {
    background-color: #eee;
}

.footer-companies-container {
    padding:20px;
    display: flex;
    max-width: 900px;
    margin: auto;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-companies-container img {
    padding: 0px 30px 0 30px;
}

.footer-address {
    background-color: white;
    
}

.footer-address-container {
    margin: auto;
    max-width: 900px;
    padding: 20px;
    position: relative;
    line-height: 28px;
}

.footer-social {
    position: absolute;
    right:20px;
}


.footer-phone {
    position: absolute;
}

.footer-address {
    padding-bottom: 20px;
}

a {
    color: black !important;
    text-decoration: none;
}