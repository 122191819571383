.about {
  height: 100%;
  display: block;
  margin: auto;
  max-width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-container {
  padding-top: 80px;
  text-align: center;
}

.about-texts {
  margin: auto;
  max-width: 1000px;
  padding: 20px;
  color: #fff;
}

.about-texts h1 {
  font-size: 6vmin;
  border-bottom: 2px white solid;
}

.about-texts h2 {
  font-size: 5vmin;
  border-bottom: 2px white solid;
  display: inline;
  padding: 0px 20px 0px 20px;
}

.about-texts p {
  font-size: 3vmin;
}

@media only screen and (max-width: 600px) {
  .about-texts p {
    font-size: 4vmin;
  }
}

.about-texts p {
  font-weight: 600;
}
