.services-container {
  max-width: 900px;
  margin: auto;
  margin-top: 0px;
  padding: 100px 0 100px 0;
}

.services {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(221, 221, 221, 1) 95%
  );
  color: black !important;
}

.services-container > h1 {
  text-align: center;
  font-weight: 900;
  font-size: 64px;
  border-bottom: 2px black solid;
  /* padding: 0px 20px 0px 20px; */
  margin: 0px 20px 0px 20px;
}
.services {
  height: 100%;
  display: block;
  margin: auto;
  max-width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.services-service {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.services-service img {
  padding: 10px;
  max-width: 400px;
  object-fit: contain;
  border-radius: 20px;
  display: inline-block;
}

.services-text {
  padding-left: 20px;
  max-width: 350px;
  min-height: 100%;
}

.services-text h1 {
  font-size: 48px;
}

#video {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .services-container h1 {
    font-size: 32px;
  }
}