.one-product-header-image {
  height: 40vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.one-product-title-container {
  max-width: 800px;
  margin: auto;
  padding-top: 120px;
  text-align: center;
}
.one-product-title-container h1 {
  color: white;
  text-align: center;
  font-weight: 1000 !important;
  font-size: 48px;
}

.one-product-content-container {
  background: rgb(204, 204, 204);
  background: linear-gradient(
    90deg,
    rgba(204, 204, 204, 1) 0%,
    rgba(255, 255, 255, 1) 95%
  );
}

.one-product-content {
  display: flex;
  padding: 30px;
  max-width: 1400px;
  margin: auto;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  font-family: "Poppins", "sans-serif";
}

.one-product-content img {
  max-width: 100%;
  width: 500px;
  object-fit: contain;
}
.one-product-content-texts {
  padding-left: 20px;
  width: 500px;
}
.one-product-content-texts p {
  font-weight: 1000;
  font-size: 24px;
  margin: 5px;
}

tr {
  /* min-width: 50vmin; */
}

table {
  width: 100%;
  /* min-width: 60vmin; */
  /* text-align: center; */
  /* margin: auto; */
  /* padding-top: 140px; */
}
table * {
  /* margin: auto; */
}

table th {
  background-color: #b6312c;
  padding: 8px;
  color: #fff;
}

table td {
  padding: 8px;
}

@media screen and (max-width: 980px) {
  table {
    width: 80vmin;
  }
}
table tr:nth-child(odd) {
  background: rgb(214, 214, 214);
}

.ahsap-kesen {
  padding-top: 20px !important;
}

.carousel-container2 {
  max-width: 800px;
}

.one-product-content-texts-content {
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 1000;
}

.carousel-container2 .slider-wrapper {
  height: 50vh !important;
  max-height: 50vh;
}

.carousel-container2 .slider-wrapper img{
  object-position: center;
  object-fit: contain;
  height: 100%;
  max-height: 100%;
}

.carousel-container2 .slider-wrapper * {
  max-height: 100%;
}

.carousel-image {
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
}

.carousel-image-container {
  height: 100%;
  max-height: 100%;
}

/* a description section at bottom with a bit of shading and a bit of padding */
/* add transtition to the color */
.carousel-image-description {
  padding: 10px;
  background: linear-gradient(to bottom, 
    rgba(100, 100, 100, 0) 0%,
    rgba(100, 100, 100, 0.5) 50%,
    rgba(100, 100, 100, 0.9) 100%
  );
  color: white;
  position: absolute;
  font-family: "Montserrat", sans-serif;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
  min-height: 60px; /* Adjust this value based on your needs */
  display: flex;
  align-items: center;
  justify-content: end;
}
