.communication {
  min-height: 100vh;
  color: white;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  padding-top: 100px;
  background-repeat: no-repeat;
  background-size: cover;
}

.communication-container {
  max-width: 900px;
  display: flex;
  margin: auto;
}

.communication-container img {
  max-width: 400px;
  object-fit: cover;
}

.communication-information {
  padding: 20px;

  margin: 0 auto;
  max-width: 600px;
  text-align: left;
}

hr {
  max-width: 800px;
}


@media only screen and (max-width: 600px) {
  .communication > h1 {
    font-size: 8vmin;
    margin: 0px 20px 0px 20px;
  }
}

.communication > hr {
  /* margin: auto 20px auto 20px; */
}
