@import url("https://fonts.googleapis.com/css?family=Roboto:700");
@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showTopText-3 {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  25%,
  41% {
    transform: translate3d(0, 66%, 0);
  }
  58%,
  74% {
    transform: translate3d(0, 33%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes borderAnimation {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

.animated-title * {
  text-align: right !important;
}

.animated-title {
  color: rgb(238, 238, 238);
  height: 90vmin;
  position: absolute;
  top: 50%;
  transform: translate(-75vmin, -50%);
  width: 70vmin;
}
.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.animated-title > div div {
  font-size: 8vmin;
  position: absolute;
}

.animated-title > .text-bottom > div {
  font-size: 4vmin !important;
  font-style: italic;
  text-align: right !important;
  width: 100%;
}

.animated-title > div div span {
  display: block;
}
// .animated-title > div.text-top {
//   border-bottom: 3px solid #000;
//   animation: borderAnimation 1s;
//   top: 0;
// }

.animated-title .border-div {
  height: 2px;
  padding: 2px;
  position: inherit;
  background-color: #000;
  animation: borderAnimation 1s;
}

.animated-title > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}

.animated-title-with-3-row > div.text-top div {
  animation: showTopText-3 1.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}

.animated-title > div.text-top div span:first-child {
  color: #ffffff;
  font-weight: 800;
}

.text-top-small {
  font-size: 7vmin !important;
}

.text-top-small2 {
  font-size: 5vmin !important;
}
.animated-title > div.text-top div span:nth-child(2) {
  color: #c2c2c2;
  font-size: 5vmin;
}
.animated-title > div.text-bottom {
  bottom: 0;
  text-align: center;
}
.animated-title > div.text-bottom img {
  height: 100%;
  padding: 20px;
  padding-right: 0px;
  display: block;
  position: absolute !important;
  right: 0;
  transform: translate3d(0, -100%, 0);
  animation: showBottomText 1s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  // top: 0;
  // transform: translate(0, -100%);

  // position: absolute !important;
  // left: 10vmax;
  max-width: 100%;
  max-height: 60vmin;
  // top: calc(50% - 36vmin);
  object-fit: cover;
}

.animation-visible {
  display: block;
}

.animation-unvisible {
  display: none;
}

.animated-title {
  text-align: left;
}

.carousel-root,
.carousel,
.slider-wrapper {
  min-height: 100% !important;
  height: 100%;
}
