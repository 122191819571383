.carousel-container {
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(187, 187, 187, 1) 96%
  );
}

.carousel-container img {
  max-height: 100vh;
  height: 100vh;
  object-fit: cover;
}

.carousel .control-dots .dot {
  width: 20px !important;
  height: 20px !important;
}

.carousel-background {
  box-shadow: inset 10px 10px 50px #fff;
}

.foreground-image img {
  position: absolute !important;
  left: 10vmax;
  max-width: 70vmin;
  max-height: 70vmin;
  top: calc(50% - 36vmin);
  object-fit: cover;
}

.text-bottom-img {
  object-fit: contain !important;
  object-position: top;
  height: 100%;
}

.MuiPaper-root {
  width: 837px;
}
@media only screen and (max-width: 1400px) {
  .MuiPaper-root {
    width: 837px;
  }
}

@media only screen and (max-width: 850px) {
  .MuiPaper-root {
    width: 70vmin;
  }
}

@media only screen and (max-width: 600) {
  .MuiPaper-root {
    width: 600px;
  }
}

@keyframes rotation {
  from {
    transform: translate(-75%, 0) rotate(0deg);
  }
  to {
    transform: translate(-75%, 0) rotate(-359deg);
  }
}
.rotate {
  overflow: auto;
  animation: rotation 2s infinite linear;
}

.carousel-container img.rotate {
  position: absolute;
  z-index: -22323;
  box-shadow: 0 0 0 0px #fff;
}

.carousel-container .smallHeader {
  font-size: 12px !important;
}


@media only screen and (max-width: 600px) {
  /* .carousel-container .smallHeader {
    font-size: 12px !important;
  } */
}
